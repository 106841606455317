import React, { useState, useEffect } from 'react'
import Container from '../../components/Container'
import Footer from '../../components/Footer/Footer'
import Form from '../../components/Form'
import styled, { useTheme } from 'styled-components'
import Img from 'gatsby-image'
import { Site } from '../../components/Site'
import { SEO } from '../../SEO'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { Main } from '../../components/Styled'
import { useLocalStorage } from 'react-use'
import { Button } from '../../components/Button'
import PDFViewer from '../../components/PDFViewer'

const DaiseeCompensationWhitePaper: React.FC<PageProps> = props => {
  const localStorageKey = 'submitted-white-paper-001'
  const [submitted, setSubmitted] = useLocalStorage<boolean>(localStorageKey, false)
  const [hasMounted, setHasMounted] = React.useState(false)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const theme = useTheme()

  const { whitepaper, pdf } = useStaticQuery(graphql`
    query CompensationImage {
      whitepaper: file(relativePath: { eq: "whitepaper-.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pdf: file(relativePath: { eq: "getting_past_compensation_effects-whitepaper.pdf" }) {
        publicURL
      }
    }
  `)

  const CTA = submitted ? 'Download or view the Daisee White Paper now' : 'Download the Daisee White Paper'

  const handleSubmit = () => {
    setTimeout(() => {}, 2000)
    setSubmitted(true)
  }

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    <Site seo={SEO.whitepaper} {...props}>
      <Main>
        <Container>
          <PageContent>
            <div className='grid'>
              <div className='form-wrapper'>
                <h1 className='cta'>{CTA}</h1>
                {!submitted && (
                  <FormWrapper>
                    <Form
                      formName='white-paper'
                      successMessage='Success! Download or view the Daisee White Paper now'
                      errorMessage='Oops, something went wrong - please try again'
                      callback={handleSubmit}
                      action='Download'
                    />
                  </FormWrapper>
                )}
              </div>
              <div>
                <div className='text'>
                  <h2>
                    Getting past “compensation effects”: The importance of monitoring and managing claimant distress{' '}
                  </h2>
                  <p>
                    <a href='https://drashtonjames.com/'>Dr Claire Ashton-James, PhD</a>
                  </p>
                </div>
                <ImageWrapper submitted={!!submitted}>
                  <div className={`button-wrapper`}>
                    <a className='button' href={pdf.publicURL} download>
                      <Button action='Download' btnHeight='auto' bgColor={theme.colors.green} />
                    </a>
                    <Button
                      action='View Online'
                      btnHeight='auto'
                      bgColor={theme.colors.green}
                      onClick={() => setIsViewerOpen(true)}
                    />
                  </div>
                  <Img fluid={whitepaper.childImageSharp.fluid} />
                </ImageWrapper>
              </div>
            </div>
            {isViewerOpen && <PDFViewer pdfPath={pdf.publicURL} onClose={() => setIsViewerOpen(false)} />}
          </PageContent>
        </Container>
      </Main>
      <Footer />
    </Site>
  )
}

const FormWrapper = styled.div`
  max-width: 786px;
  margin: 0 auto 3rem;
`

type ImageWrapperProps = {
  submitted: boolean
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  height: fit-content;
  box-shadow: ${({ theme }) => theme.boxShadow.large};

  ${({ theme }) => theme.breakpoints.tablet} {
    margin: 0;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    opacity: ${({ submitted }) => (submitted ? 1 : 0)};
    transition: opacity 0.4s ease;
  }

  .button-wrapper {
    opacity: ${({ submitted }) => (submitted ? 1 : 0)};
    display: ${({ submitted }) => (submitted ? 'grid' : 'none')};
  }
`

const PageContent = styled.div`
  margin: 9rem 0 0;

  h1,
  a {
    color: ${({ theme }) => theme.colors.purple};
  }

  .button-wrapper {
    position: absolute;
    display: grid;
    grid-auto-flow: column;
    gap: 1.5rem;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);

    a {
      text-decoration: none;
    }
  }

  .submitted {
    .button-wrapper {
      opacity: 1;
      display: grid;
    }
    &:before {
      opacity: 1;
    }
  }

  a {
    text-decoration: underline;
  }

  h2,
  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: -0.5px;
  }

  h2 {
    position: relative;
    padding-left: 1rem;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      height: 2rem;
      background: ${({ theme }) => theme.colors.purple};
      width: 4px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    padding-bottom: 3rem;

    ${({ theme }) => theme.breakpoints.tablet} {
      grid-template-columns: 1fr;
    }
  }

  .cta {
    font-weight: 600;
    font-size: 2rem;
    margin: 0 0 2rem;
    color: ${({ theme }) => theme.colors.purple};
  }

  .text {
    display: grid;
    gap: 1.5rem;
    height: fit-content;
    margin-bottom: 2rem;
  }
`

export default DaiseeCompensationWhitePaper
